import React, { useEffect, useState } from "react";
import { graphql } from "gatsby";
import { get, compact, last } from "lodash";
import PropTypes from "prop-types";
import { useQuery, useLazyQuery } from "@apollo/react-hooks";

import { ContentBlock, TextBlock } from "../../../components/Blocks";
import Pages from "../../../components/Pages";
import { makeUrl, removePreloader } from "../../../helpers";
import Breadcrumbs from "../../../components/Layout/Breadcrumbs";
import { Share } from "../../../widgets";
import { MetaDateModified, MetaDescription, MetaDateDateCreated, MetaDatePublished, MetaTranscript, MetaImage } from "../../../components/Meta";
import { AudioPlayer } from "../../../components/Media";
import Contents from "../../../components/Contents";
import { AUDIOGUIDES_CONTENT, AUDIOGUIDES_MAX_UPDATE } from "../../../queries/queries.graphql";

export const query = graphql`
	query audioguide($slug: String!) {
		hasura {
			...Audioguides
		}
	}
`;

export default function MediaAudiosPage({ location, data, pageContext }) {
	const [audioguide, setAudioguide] = useState(get(data, "hasura.v_media_audio_guides[0]", {}));

	const maxUpdated = new Date(get(data, "hasura.v_media_audio_guides_aggregate.aggregate.max.updated_at", new Date()));

	const path = last(compact(location.pathname.split("/")));
	const categorySlug = get(pageContext, "audio_category.slug", "");
	const categoryTitle = get(pageContext, "audio_category.title", "");

	const audioguideOptions = {
		variables: {
			location: path,
		},
		displayName: "AudioguideQuery",
		fetchPolicy: "cache-and-network",
		ssr: false,
	};

	const { loading: maxUpdateLoading, data: maxUpdateData, error: maxUpdateError } = useQuery(AUDIOGUIDES_MAX_UPDATE, audioguideOptions);

	const [loadAudioguide, { called: audioguideCalled, loading: audioguideLoading, data: audioguideData, error: audioguideError }] = useLazyQuery(AUDIOGUIDES_CONTENT, audioguideOptions);

	useEffect(() => {
		const currentMaxUpdated = new Date(get(maxUpdateData, "v_media_audio_guides_aggregate.aggregate.max.updated_at", new Date()));

		if (maxUpdateData && !maxUpdateError) {
			if (+currentMaxUpdated !== +maxUpdated) {
				loadAudioguide();
			} else {
				removePreloader();
			}
		} else if (maxUpdateError) {
			console.error("Invalid load ArticleMaxUpdate", { maxUpdateError });
			removePreloader();
		}
	}, [maxUpdateLoading]);

	useEffect(() => {
		if (audioguideData && !audioguideError) {
			setAudioguide(get(audioguideData, "v_media_audio_guides[0]", audioguide));
			removePreloader(audioguideCalled);
		} else if (audioguideError) {
			console.error("Invalid load AudioguideData", { audioguideError });
			removePreloader();
		}
	}, [audioguideLoading]);

	const slug = get(audioguide, "slug", "");
	const title_full = get(audioguide, "title_full", "");
	const page_title_full = get(audioguide, "page_title_full", "");
	const page_title_short = get(audioguide, "page_title_short", "");
	const content_blocks = get(audioguide, "content_blocks", []);
	const main_image_preview = get(audioguide, "main_image_preview", {});
	const main_image = get(audioguide, "main_image", {});
	const updated_at = get(audioguide, "updated_at", new Date());
	const created_at = get(audioguide, "created_at", new Date());
	const imageSrc = get(main_image_preview, "src", get(main_image, "src", ""));
	const audioSrc = get(audioguide, "audio.src", {});

	const belltowerSrc = makeUrl.belltower(get(audioguide, "belltowers_media_audios[0].belltower", ""));
	const cathedralSrc = makeUrl.cathedral(get(audioguide, "cathedrals_media_audios[0].cathedral", ""));
	const chapelrSrc = makeUrl.chapel(get(audioguide, "chapels_media_audios[0].chapel", ""));
	const churchSrc = makeUrl.church(get(audioguide, "churches_media_audios[0].church", ""));
	const citySrc = makeUrl.city(get(audioguide, "cities_media_audios[0].city", ""));
	const cityObjectrSrc = makeUrl.cityObject(get(audioguide, "city_objects_media_audios[0].city_object", ""));
	const hermitageSrc = makeUrl.hermitage(get(audioguide, "hermitages_media_audios[0].hermitage", ""));
	const shrineSrc = makeUrl.shrine(get(audioguide, "media_audios_shrines[0].shrine", ""));
	const templeSrc = makeUrl.temple(get(audioguide, "media_audios_temples[0].temple", ""));
	const wellSrc = makeUrl.well(get(audioguide, "media_audios_wells[0].well", ""));
	const monasterySrc = makeUrl.monastery(get(audioguide, "monasteries_media_audios[0].monastery", ""));
	const museumSrc = makeUrl.museum(get(audioguide, "media_audios_museums[0].museum", ""));
	const objectSrc = belltowerSrc || chapelrSrc || churchSrc || citySrc || cityObjectrSrc || hermitageSrc || shrineSrc || templeSrc || wellSrc || monasterySrc || museumSrc || cathedralSrc;
	const url = makeUrl.mediaAudioguide({ slug });

	const buttonData = {
		display: objectSrc,
		text: "Перейти к странице",
		slug: objectSrc,
	};

	return (
		<Pages
			seo={audioguide}
			url={url}
		>
			<div itemScope itemType="https://schema.org/AudioObject">
				<MetaDateModified content={updated_at} />
				<MetaDescription content={page_title_full} />
				<MetaDateDateCreated content={created_at} />
				<MetaDatePublished content={created_at} />
				<MetaImage content={imageSrc} />
				<MetaTranscript content={"AudioObject"} />
				<ContentBlock key={"breadcrumbs"}>
					<div className="container">
						<Breadcrumbs
							currentLabel={title_full}
							pageContext={
								{
									item: {
										title_full: "Медиатека",
										slug: "media",
									},
									item2: {
										title_full: "Все аудиогиды",
									},
									item3: {
										title_full: categoryTitle,
										slug: categorySlug,
									},
								}
							}
						/>
					</div>
				</ContentBlock>
				<ContentBlock key={"player"}>
					<div className={"container"}>
						<TextBlock title={title_full}>
							<AudioPlayer url={audioSrc} />
						</TextBlock>
					</div>
				</ContentBlock>
				<ContentBlock key={"contents"}>
					<div className={"container"}>
						<Contents items={content_blocks} date={updated_at} button={buttonData}/>
					</div>
				</ContentBlock>
				<div className={"container"}>
					<Share
						url={url}
						pageTitleShort={page_title_short}
						pageTitleFull={page_title_full}
						imageSrc={imageSrc}
					/>
				</div>
			</div>
		</Pages>
	);
}

MediaAudiosPage.propTypes = {
	data: PropTypes.object,
	location: PropTypes.object,
	pageContext: PropTypes.object,
};

MediaAudiosPage.defaultProps = {
	data: {},
	location: {
		pathname: "",
	},
	pageContext: {},
};
